import { OptionalAppOptions } from '@backstage/app-defaults';
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  UnifiedThemeProvider,
} from '@backstage/theme';
import { Theme } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';
import React from 'react';

export const nexusLight = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      entities: {
        system: '#277B7B',
        component: '#8A4768',
        api: '#447395',
        group: '#557F70',
        domain: '#B25D1C',
        fallback: '#000000',
      },
    },
  }),
});

export const nexusDark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      entities: {
        system: '#007A7A',
        component: '#973060',
        api: '#266AA6',
        group: '#40826B',
        domain: '#D98200',
        fallback: '#000000',
      },
    },
  }),
});

export interface NexusPalette extends Palette {
  entities: Record<string, string>;
}

export interface NexusTheme extends Theme {
  palette: NexusPalette;
}

export const nexusThemes: OptionalAppOptions['themes'] = [
  {
    id: 'light',
    title: 'Light',
    variant: 'light',
    Provider: ({ children }: any) => (
      <UnifiedThemeProvider theme={nexusLight}>{children}</UnifiedThemeProvider>
    ),
  },
  {
    id: 'dark',
    title: 'Dark',
    variant: 'dark',
    Provider: ({ children }: any) => (
      <UnifiedThemeProvider theme={nexusDark}>{children}</UnifiedThemeProvider>
    ),
  },
] as const;
