import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import { IconComponent } from '@backstage/core-plugin-api';

export function EntityIcon({
  icon,
  ...props
}: {
  icon: IconComponent | undefined;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  className?: string;
}) {
  const Icon = (icon as OverridableComponent<SvgIconTypeMap>) ?? SvgIcon;
  return <Icon {...props} />;
}
